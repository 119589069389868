import { AppRoutes } from 'app/routes';

export const environment = {
  environmentName: 'live',
  production: true,
  api: 'https://prod.eventpage.ai',
  assets: 'https://app.eventpage.ai',
  website: 'https://app.eventpage.ai',
  landingAdmin: AppRoutes.Admin.root,
  landingCustomer: AppRoutes.Customer.dashboard,
  googleFontsApiKey: 'AIzaSyBnHH2VqQ4Cab_kdi2ZSi9zREIkloF-QX4',
  logoAsset: './assets/logo.svg',
  logoDarkAsset: './assets/logo-dark.svg',
  iconAsset: './assets/icon.svg',
  iconDarkAsset: './assets/icon-dark.svg',
  platform: 'eventpage.ai',
  platformShort: 'eventpage',
  defaultSenderEmail: 'hello@eventpage.ai',
  stripePublicKey:
    'pk_live_51OBlpHKAiYYGDtEBvYaOQomWCosHNZtB1fqQt650tDntwvD22vOfi5Ovjr5adPLM8VJRxTcTLRkkPpT6kEk6Imey005yYUohrf',
  weddingEventCategoryId: 4,
};
